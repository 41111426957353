import React, { useEffect, useRef, useState } from "react";

import {
  Checkbox,
  Chip,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  CircularProgress,
  Box
} from "@material-ui/core";

import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import _without from "lodash/without";
import {
  GetTeacherTiming,
  GetTeacherTimingKids,
  GetTimingSubjectWise,
  TeacherWeeklyTiming,
  UpdateTeacherTiming,
} from "../../redux/actions/SessionAction";
import { FilterTimeSlot } from "../../helper/sessionHelper";
import { CurrentTeacher } from "../../redux/actions/AuthAction";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  card: {
    padding: "2rem",
  },
  cardDate: {
    padding: "0.6rem",
    margin: "1rem 0",
    width: "100%",
    backgroundColor: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%",
    maxWidth: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#3ea175",
    color: "#fff",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


const time = [
  "N/A",
  "00 - 01",
  "01 - 02",
  "02 - 03",
  "03 - 04",
  "04 - 05",
  "05 - 06",
  "06 - 07",
  "07 - 08",
  "08 - 09",
  "09 - 10",
  "10 - 11",
  "11 - 12",
  "12 - 13",
  "13 - 14",
  "14 - 15",
  "15 - 16",
  "16 - 17",
  "17 - 18",
  "18 - 19",
  "19 - 20",
  "20 - 21",
  "21 - 22",
  "22 - 23",
  "23 - 00",
  ];


const SelectTimings = ({
  classes,
  handleChange,
  handleDelete,
  selectedTimings,
  weekDay,
  weekDayTiming,
  type,
  demoDemand,
  existingTimings
}) => {
  console.log(weekDay ," : ",selectedTimings)
  // Function to determine the tag and text color based on the time range
  const getTimeTagAndColor = (timeRange) => {
    if(type === "Demo"){
      const priorityIndex = demoDemand.findIndex((demandSlot) => {
        return demandSlot.localStartTime === parseInt(timeRange.split("-")[0])
      })
      console.log("demandData ---> ",timeRange,priorityIndex);
      if(priorityIndex >= 0 && priorityIndex <= 5){
        return { tag: "High Demand", color: "#476930",  textColor:"white" };
      }
      else if(priorityIndex >= 0 && priorityIndex <= 10){
        return { tag: "Medium Demand", color: "#FFEE00", textColor:"black"  };
      }
      else if(priorityIndex >= 0){
        return { tag: "Low Demand", color: "#F01E2C", textColor:"white" };
      }
      else{
        if(parseInt(timeRange.split("-")[0]) >= 17){
          return { tag: "Medium Demand", color: "#FFEE00", textColor:"black"  };
        }
        else{
          return { tag: "Low Demand", color: "#F01E2C", textColor:"white" };
        }
      }
    }
    else{
      if (timeRange.includes("08 - 09") || timeRange.includes("09 - 10")) {
        return { tag: "Low Demand", color: "#F01E2C", textColor:"white" };
      } else if (
        timeRange.includes("10 - 11") ||
        timeRange.includes("11 - 12") ||
        timeRange.includes("12 - 13")
      ) {
        return { tag: "Medium Demand", color: "#FFEE00", textColor:"black"  };
      } else if (
        timeRange.includes("13 - 14") ||
        timeRange.includes("14 - 15") ||
        timeRange.includes("15 - 16") ||
        timeRange.includes("16 - 17")
      ) {
        return { tag: "Moderate Demand", color: "#F37413",  textColor:"white" };
      } else if (
        timeRange.includes("17 - 18") ||
        timeRange.includes("18 - 19") ||
        timeRange.includes("19 - 20") ||
        timeRange.includes("20 - 21") ||
        timeRange.includes("21 - 22") ||
        timeRange.includes("22 - 23")
      ) {
        return { tag: "High Demand", color: "#476930",  textColor:"white" };
      } else {
        return { tag: "", color: "black", textColor:"white" }; // Default values
      }
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-chip-checkbox-label">
        {weekDay} Timing
      </InputLabel>
      <Select
        labelId="demo-mutiple-chip-checkbox-label"
        id="demo-mutiple-chip-checkbox"
        multiple
        value={weekDayTiming}
        onChange={(e) => {
          handleChange(e, weekDay);
        }}
        onOpen={() => console.log("select opened")}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                clickable
                className={classes.chip}
                onDelete={(e) => handleDelete(e, value, weekDay)}
                onClick={() => console.log("clicked chip")}
              />
            ))}
          </div>
        )}
      >
        {time.map((name) => {
          if (selectedTimings && !selectedTimings.includes(name)) {
            const { tag, color, textColor } = getTimeTagAndColor(name);
            const alreadyExistingSlot = existingTimings.includes(name);
            return (
              <MenuItem key={name} value={name} disabled={alreadyExistingSlot}>
                {weekDayTiming ? (
                  <Checkbox checked={weekDayTiming.includes(name)} />
                ) : (
                  <Checkbox />
                )}
                
                <ListItemText primary={alreadyExistingSlot?(`${name} (Selected in ${type === "Demo"?"Regular":"Demo"} Hours)`):name}/>
                <div style={{width:"150px"}}>
                  <ListItemText primary={tag} style={{color: textColor, fontWeight:"bold", background: color, textAlign: "center", borderRadius: "25px"}} />
                </div>
                
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};


const RegularWorkingHours = ({ subject, replicate, type, demoDemand}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [subjectSwitchLoader, setSubjectSwitchLoader] = useState(false)
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [loder, setLoder] = useState(false);
  const [updateWorkingHour, setUpdateWorkingHour] = useState(false);
  const [alreadySelectedTimeSlot, setAlreadySelectedTimeSlot] = useState(null);

  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const teacherSchedule = useSelector((state) => state.TeacherReducer.timing);
  const existingSlotsData = useSelector((state) => state.TeacherReducer.existingSlots);

  const [sundayTiming, setSundayTiming] = useState(
    teacherSchedule ? (teacherSchedule[0] ? teacherSchedule[0].Sunday : []) : []
  );
  const [mondayTiming, setMondayTiming] = useState(
    teacherSchedule ? (teacherSchedule[0] ? teacherSchedule[0].Monday : []) : []
  );
  const [tuesdayTiming, setTuesdayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Tuesday
        : []
      : []
  );
  const [weddayTiming, setWeddayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Wednesday
        : []
      : []
  );
  const [thursdayTiming, setThursdayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Thursday
        : []
      : []
  );
  const [fridayTiming, setFridayTiming] = useState(
    teacherSchedule ? (teacherSchedule[0] ? teacherSchedule[0].Friday : []) : []
  );
  const [satdayTiming, setSatdayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Saturday
        : []
      : []
  );

  const isInitialRender = useRef(true);
  useEffect(() => {
    if (teacherSchedule) {
      if (teacherSchedule !== null && teacherSchedule.length > 0) {
        setSundayTiming(teacherSchedule[0].Sunday);
        setMondayTiming(teacherSchedule[0].Monday);
        setTuesdayTiming(teacherSchedule[0].Tuesday);
        setWeddayTiming(teacherSchedule[0].Wednesday);
        setThursdayTiming(teacherSchedule[0].Thursday);
        setFridayTiming(teacherSchedule[0].Friday);
        setSatdayTiming(teacherSchedule[0].Saturday);
      }

      if (
        teacherSchedule[0].Sunday.length > 0 ||
        teacherSchedule[0].Monday.length > 0 ||
        teacherSchedule[0].Tuesday.length > 0 ||
        teacherSchedule[0].Wednesday.length > 0 ||
        teacherSchedule[0].Thursday.length > 0 ||
        teacherSchedule[0].Friday.length > 0 ||
        teacherSchedule[0].Saturday.length > 0
      ) {
        setUpdateWorkingHour(true);
      } else {
        setUpdateWorkingHour(false);
      }
    }


    if (subject) {
      setSubjectSwitchLoader(true)
      const onSuccess = (result) => {
        // console.log(FilterTimeSlot(result))
        setSubjectSwitchLoader(false)
        setAlreadySelectedTimeSlot(FilterTimeSlot(result));
      };
      const onError = (msg) => {
        setSubjectSwitchLoader(false)
        openSnackbar(msg);
      };
      dispatch(GetTimingSubjectWise(subject, onSuccess, onError));
    }

  }, [teacherSchedule, subject]);

  const handleChange = (event, week) => {
    if (week === "Sunday") {
      if (event.target.value.includes("N/A") && !sundayTiming.includes("N/A")) {
        setSundayTiming(["N/A"]);
      } else {
        setSundayTiming(event.target.value);
      }
    } else if (week === "Monday") {
      if (event.target.value.includes("N/A") && !mondayTiming.includes("N/A")) {
        setMondayTiming(["N/A"]);
      } else {
        setMondayTiming(event.target.value);
      }
    } else if (week === "Tuesday") {
      if (
        event.target.value.includes("N/A") &&
        !tuesdayTiming.includes("N/A")
      ) {
        setTuesdayTiming(["N/A"]);
      } else {
        setTuesdayTiming(event.target.value);
      }
    } else if (week === "Wednesday") {
      if (event.target.value.includes("N/A") && !weddayTiming.includes("N/A")) {
        setWeddayTiming(["N/A"]);
      } else {
        setWeddayTiming(event.target.value);
      }
    } else if (week === "Thursday") {
      if (
        event.target.value.includes("N/A") &&
        !thursdayTiming.includes("N/A")
      ) {
        setThursdayTiming(["N/A"]);
      } else {
        setThursdayTiming(event.target.value);
      }
    } else if (week === "Friday") {
      if (event.target.value.includes("N/A") && !fridayTiming.includes("N/A")) {
        setFridayTiming(["N/A"]);
      } else {
        setFridayTiming(event.target.value);
      }
    } else if (week === "Saturday") {
      if (event.target.value.includes("N/A") && !satdayTiming.includes("N/A")) {
        setSatdayTiming(["N/A"]);
      } else {
        setSatdayTiming(event.target.value);
      }
    }
  };

  const handleDelete = (e, value, week) => {
    e.preventDefault();
    if (week === "Sunday") {
      setSundayTiming((current) => _without(current, value));
    } else if (week === "Monday") {
      setMondayTiming((current) => _without(current, value));
    } else if (week === "Tuesday") {
      setTuesdayTiming((current) => _without(current, value));
    } else if (week === "Wednesday") {
      setWeddayTiming((current) => _without(current, value));
    } else if (week === "Thursday") {
      setThursdayTiming((current) => _without(current, value));
    } else if (week === "Friday") {
      setFridayTiming((current) => _without(current, value));
    } else if (week === "Saturday") {
      setSatdayTiming((current) => _without(current, value));
    }
  };

  useEffect(() => {
    // Skip the first render
  // if(replicate!==undefined){
    console.log("mylogrender", replicate, isInitialRender.current)

    const submitForm = () => {
      setLoder(true);
      var data;

      const onSuccess = (msg) => {
        openSnackbar(msg);
        setLoder(false);

        dispatch(
          GetTeacherTiming({
            teacherId: teacher._id,
            forKids: false,
            subject: subject,
            type:(type ?? "Regular")
          })
        );
        dispatch(
          GetTeacherTimingKids({
            teacherId: teacher._id,
            forKids: true,
            subject: subject,
          })
        );
        dispatch(CurrentTeacher({
          userid: teacher._id,
          timezone: time_zone.timezone
        }))
      };

      const onError = (msg) => {
        setLoder(false);
        openSnackbar(msg);
      };

      if (teacherSchedule) {
        if (updateWorkingHour) {
          data = {
            _id: teacher._id,
            Sunday: sundayTiming.filter((x) => x !== 'N/A'),
            Monday: mondayTiming.filter((x) => x !== 'N/A'),
            Tuesday: tuesdayTiming.filter((x) => x !== 'N/A'),
            Wednesday: weddayTiming.filter((x) => x !== 'N/A'),
            Thursday: thursdayTiming.filter((x) => x !== 'N/A'),
            Friday: fridayTiming.filter((x) => x !== 'N/A'),
            Saturday: satdayTiming.filter((x) => x !== 'N/A'),
            timeZone: time_zone.timezone,
            subject: subject,
            allowWeekSlotsReplication: replicate,

            // workingHourType:type

          };
          dispatch(UpdateTeacherTiming(data, onSuccess, onError));
        } else {
          data = {
            _id: teacher._id,
            Sunday: sundayTiming.filter((x) => x !== 'N/A'),
            Monday: mondayTiming.filter((x) => x !== 'N/A'),
            Tuesday: tuesdayTiming.filter((x) => x !== 'N/A'),
            Wednesday: weddayTiming.filter((x) => x !== 'N/A'),
            Thursday: thursdayTiming.filter((x) => x !== 'N/A'),
            Friday: fridayTiming.filter((x) => x !== 'N/A'),
            Saturday: satdayTiming.filter((x) => x !== 'N/A'),
            isDemo: false,
            timeZone: time_zone.timezone,
            subject: subject,
            allowWeekSlotsReplication: replicate,

            // workingHourType:type

          };

          dispatch(TeacherWeeklyTiming(data, onSuccess, onError));
        }
      } else {
        // openSnackbar('Something went wrong. Please reload and try again.');
        setLoder(false);
      }
    };

    // Call the SubmitForm function only when replicate changes
    if(isInitialRender.current===false) submitForm();
    // else isInitialRender.current = false;

  // }
  // return () => {console.log("mylogrender unmounting");isInitialRender.current=true}
  }, [replicate]);

  useEffect(()=>{
    console.log("mylogrender mounting");
    isInitialRender.current = false;
    return () => {console.log("mylogrender unmounting");isInitialRender.current=true}
  }, [subject])

  const SubmitForm = () => {
    setLoder(true);
    var data;

    const onSuccess = (msg) => {
      openSnackbar(msg);
      setLoder(false);

      dispatch(
        GetTeacherTiming({
          teacherId: teacher._id,
          forKids: false,
          subject: subject,
          type:(type ?? "Regular")
        })
      );
      dispatch(
        GetTeacherTimingKids({
          teacherId: teacher._id,
          forKids: true,
          subject: subject,
        })
      );

    };
    const onError = (msg) => {
      setLoder(false);
      openSnackbar(msg);
    };

    if (teacherSchedule) {
      if (updateWorkingHour) {
        data = {
          _id: teacher._id,
          Sunday: sundayTiming.filter(x => x !== 'N/A'),
          Monday: mondayTiming.filter(x => x !== 'N/A'),
          Tuesday: tuesdayTiming.filter(x => x !== 'N/A'),
          Wednesday: weddayTiming.filter(x => x !== 'N/A'),
          Thursday: thursdayTiming.filter(x => x !== 'N/A'),
          Friday: fridayTiming.filter(x => x !== 'N/A'),
          Saturday: satdayTiming.filter(x => x !== 'N/A'),
          timeZone: time_zone.timezone,
          subject: subject,
          allowWeekSlotsReplication:replicate,

          // workingHourType:type

        };
        dispatch(UpdateTeacherTiming(data, onSuccess, onError));
      } else {
        data = {
          _id: teacher._id,
          Sunday: sundayTiming.filter(x => x !== 'N/A'),
          Monday: mondayTiming.filter(x => x !== 'N/A'),
          Tuesday: tuesdayTiming.filter(x => x !== 'N/A'),
          Wednesday: weddayTiming.filter(x => x !== 'N/A'),
          Thursday: thursdayTiming.filter(x => x !== 'N/A'),
          Friday: fridayTiming.filter(x => x !== 'N/A'),
          Saturday: satdayTiming.filter(x => x !== 'N/A'),
          isDemo: false,
          timeZone: time_zone.timezone,
          subject: subject,
          allowWeekSlotsReplication:replicate,

          // workingHourType:type

        };

        dispatch(TeacherWeeklyTiming(data, onSuccess, onError));
      }
    } else {
      openSnackbar("something went wrong please reloads & try again");
    }
  };


  if(subjectSwitchLoader){
     return(
      <Box mx="auto">
        <CircularProgress />
      </Box>
     )
  }else{
  return (
    <>

      <SelectTimings
        classes={classes}
        handleChange={handleChange}
        handleDelete={handleDelete}
        selectedTimings={alreadySelectedTimeSlot?.Sunday}
        existingTimings={existingSlotsData ? (existingSlotsData[0] ? existingSlotsData[0].Sunday : []) : []}
        weekDay={"Sunday"}
        weekDayTiming={sundayTiming}
        type={type}
        demoDemand={demoDemand}
      />

      <SelectTimings
        classes={classes}
        handleChange={handleChange}
        handleDelete={handleDelete}
        selectedTimings={alreadySelectedTimeSlot?.Monday}
        existingTimings={existingSlotsData ? (existingSlotsData[0] ? existingSlotsData[0].Monday : []) : []}
        weekDay={"Monday"}
        weekDayTiming={mondayTiming}
        type={type}
        demoDemand={demoDemand}
      />

      <SelectTimings
        classes={classes}
        handleChange={handleChange}
        handleDelete={handleDelete}
        selectedTimings={alreadySelectedTimeSlot?.Tuesday}
        existingTimings={existingSlotsData ? (existingSlotsData[0] ? existingSlotsData[0].Tuesday : []) : []}
        weekDay={"Tuesday"}
        weekDayTiming={tuesdayTiming}
        type={type}
        demoDemand={demoDemand}
      />

      <SelectTimings
        classes={classes}
        handleChange={handleChange}
        handleDelete={handleDelete}
        selectedTimings={alreadySelectedTimeSlot?.Wednesday}
        existingTimings={existingSlotsData ? (existingSlotsData[0] ? existingSlotsData[0].Wednesday : []) : []}
        weekDay={"Wednesday"}
        weekDayTiming={weddayTiming}
        type={type}
        demoDemand={demoDemand}
      />

      <SelectTimings
        classes={classes}
        handleChange={handleChange}
        handleDelete={handleDelete}
        selectedTimings={alreadySelectedTimeSlot?.Thursday}
        existingTimings={existingSlotsData ? (existingSlotsData[0] ? existingSlotsData[0].Thursday : []) : []}
        weekDay={"Thursday"}
        weekDayTiming={thursdayTiming}
        type={type}
        demoDemand={demoDemand}
      />

      <SelectTimings
        classes={classes}
        handleChange={handleChange}
        handleDelete={handleDelete}
        selectedTimings={alreadySelectedTimeSlot?.Friday}
        existingTimings={existingSlotsData ? (existingSlotsData[0] ? existingSlotsData[0].Friday : []) : []}
        weekDay={"Friday"}
        weekDayTiming={fridayTiming}
        type={type}
        demoDemand={demoDemand}
      />

      <SelectTimings
        classes={classes}
        handleChange={handleChange}
        handleDelete={handleDelete}
        selectedTimings={alreadySelectedTimeSlot?.Saturday}
        existingTimings={existingSlotsData ? (existingSlotsData[0] ? existingSlotsData[0].Saturday : []) : []}
        weekDay={"Saturday"}
        weekDayTiming={satdayTiming}
        type={type}
        demoDemand={demoDemand}
      />

      <Button
        variant="contained"
        style={{ marginTop: "2rem" }}
        color="primary"
        onClick={SubmitForm}
        disabled={loder ? true : false}
      >
        {updateWorkingHour ? (
          loder ? (
            <CircularProgress />
          ) : (
            "Update"
          )
        ) : loder ? (
          <CircularProgress />
        ) : (
          "Submit"
        )}
      </Button>
    </>
  );
}
}

export default RegularWorkingHours;