export const LOAD_USER = "LOAD_USER"
export const FETCH_TEACHER_PENDING_REQUEST = "FETCH_TEACHER_PENDING_REQUEST"
export const TEACHER_UPCOMING_SESSION = "TEACHER_UPCOMING_SESSION"
export const TEACHER_PAST_SESSION = "TEACHER_PAST_SESSION"
export const TEACHER_DECLINE = "TEACHER_DECLINE"
export const TEACHER_TIMING = "TEACHER_TIMING"
export const TEACHER_EXISTING_TIMING = "TEACHER_EXISTING_TIMING"
export const TEACHER_TIMING_KIDS = "TEACHER_TIMING_KIDS"
export const UNBLOCK_LEARNER = "UNBLOCK_LEARNER"
export const GET_BLOCK_LEARNER = "UNBLOCK_LEARNER"
export const CANCEL_AND_REASSIGN = "CANCEL_AND_REASSIGN"
export const ADD_FEEDBACK = "ADD_FEEDBACK"
export const SET_UNREAD_MSG = "SET_UNREAD_MSG"
export const LOAD_INSTANT_BOOKING_SOCKET = "LOAD_INSTANT_BOOKING_SOCKET"
export const UPDATE_INSTANT_BOOKING = "UPDATE_INSTANT_BOOKING"
export const LOAD_INSTANT_BOOKING_REQUESTS = "LOAD_INSTANT_BOOKING_REQUESTS"
export const UPDATE_INSTANT_BOOKING_REQUESTS = "UPDATE_INSTANT_BOOKING_REQUESTS"
export const DELETE_INSTANT_BOOKING_REQUESTS = "DELETE_INSTANT_BOOKING_REQUESTS"
export const UPDATE_INSTANT_BOOKING_REQUESTS_COUNT = "UPDATE_INSTANT_BOOKING_REQUESTS_COUNT"
export const LOAD_CONVERSION_STATS = "LOAD_CONVERSION_STATS"
