import React, { useEffect, useRef, useState } from "react";

import {
  Checkbox,
  Chip,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  CircularProgress,
  FormControlLabel,
  Box,
} from "@material-ui/core";

import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import _without from "lodash/without";
import {
  GetTeacherTiming,
  GetTeacherTimingKids,
  TeacherWeeklyTimingKids,
} from "../../redux/actions/SessionAction";
import { CurrentTeacher } from "../../redux/actions/AuthAction";

const useStyles = makeStyles((theme) => ({
  root: {
    // ...theme.typography.button,
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5),
  },
  card: {
    padding: "2rem",
  },
  cardDate: {
    padding: "0.6rem",
    margin: "1rem 0",
    width: "100%",
    backgroundColor: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%",
    maxWidth: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#3ea175",
    color: "#fff",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const time = [
  "N/A",
  "00 - 01",
  "01 - 02",
  "02 - 03",
  "03 - 04",
  "04 - 05",
  "05 - 06",
  "06 - 07",
  "07 - 08",
  "08 - 09",
  "09 - 10",
  "10 - 11",
  "11 - 12",
  "12 - 13",
  "13 - 14",
  "14 - 15",
  "15 - 16",
  "16 - 17",
  "17 - 18",
  "18 - 19",
  "19 - 20",
  "20 - 21",
  "21 - 22",
  "22 - 23",
  "23 - 00",
];

const SelectTimings = ({
  classes,
  handleChange,
  handleDelete,
  regularTiming,
  teacherNormalScheduleTime,
  weekDay,
  weekDayTiming,
}) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-chip-checkbox-label">
        {weekDay} Timing
      </InputLabel>
      <Select
        labelId="demo-mutiple-chip-checkbox-label"
        id="demo-mutiple-chip-checkbox"
        multiple
        value={weekDayTiming}
        onChange={(e) => {
          handleChange(e, weekDay);
        }}
        onOpen={() => console.log("select opened")}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                clickable
                className={classes.chip}
                onDelete={(e) => handleDelete(e, value, weekDay)}
                onClick={() => console.log("clicked chip")}
              />
            ))}
          </div>
        )}
      >
        {time.map((name) => {
          if (
            regularTiming === true &&
            (teacherNormalScheduleTime.includes(name) || name === "N/A")
          ) {
            return (
              <MenuItem key={name} value={name}>
                {weekDayTiming ? (
                  <Checkbox checked={weekDayTiming.includes(name)} />
                ) : (
                  <Checkbox />
                )}

                <ListItemText primary={name} />
              </MenuItem>
            );
          } else if (regularTiming === false) {
            return (
              <MenuItem key={name} value={name}>
                {weekDayTiming ? (
                  <Checkbox checked={weekDayTiming.includes(name)} />
                ) : (
                  <Checkbox />
                )}
                <ListItemText primary={name} />
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};

const KidsWorkingHours = ({ subject, regularTiming, replicate }) => {

  const classes = useStyles();

  const dispatch = useDispatch();

  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [loder, setLoder] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const teacherSchedule = useSelector(
    (state) => state.TeacherReducer.kidsTiming
  );
  const teacherNormalSchedule = useSelector(
    (state) => state.TeacherReducer.timing
  );

  const [updateWorkingHour, setUpdateWorkingHour] = useState(false);
  const [sundayTiming, setSundayTiming] = useState(
    teacherSchedule ? (teacherSchedule[0] ? teacherSchedule[0].Sunday : []) : []
  );
  const [mondayTiming, setMondayTiming] = useState(
    teacherSchedule ? (teacherSchedule[0] ? teacherSchedule[0].Monday : []) : []
  );
  const [tuesdayTiming, setTuesdayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Tuesday
        : []
      : []
  );
  const [weddayTiming, setWeddayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Wednesday
        : []
      : []
  );
  const [thursdayTiming, setThursdayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Thursday
        : []
      : []
  );
  const [fridayTiming, setFridayTiming] = useState(
    teacherSchedule ? (teacherSchedule[0] ? teacherSchedule[0].Friday : []) : []
  );
  const [satdayTiming, setSatdayTiming] = useState(
    teacherSchedule
      ? teacherSchedule[0]
        ? teacherSchedule[0].Saturday
        : []
      : []
  );
  const isInitialRender = useRef(true);
  const checkSameAs = () => {
    if (!duplicate) {
      setSundayTiming(teacherNormalSchedule[0].Sunday);
      setMondayTiming(teacherNormalSchedule[0].Monday);
      setTuesdayTiming(teacherNormalSchedule[0].Tuesday);
      setWeddayTiming(teacherNormalSchedule[0].Wednesday);
      setThursdayTiming(teacherNormalSchedule[0].Thursday);
      setFridayTiming(teacherNormalSchedule[0].Friday);
      setSatdayTiming(teacherNormalSchedule[0].Saturday);
    } else {
      setSundayTiming(teacherSchedule[0].Sunday);
      setMondayTiming(teacherSchedule[0].Monday);
      setTuesdayTiming(teacherSchedule[0].Tuesday);
      setWeddayTiming(teacherSchedule[0].Wednesday);
      setThursdayTiming(teacherSchedule[0].Thursday);
      setFridayTiming(teacherSchedule[0].Friday);
      setSatdayTiming(teacherSchedule[0].Saturday);
    }
    setDuplicate(!duplicate);
  };

  useEffect(() => {
    if (teacherSchedule) {
      if (teacherSchedule !== null && teacherSchedule.length > 0) {
        setSundayTiming(teacherSchedule[0].Sunday);
        setMondayTiming(teacherSchedule[0].Monday);
        setTuesdayTiming(teacherSchedule[0].Tuesday);
        setWeddayTiming(teacherSchedule[0].Wednesday);
        setThursdayTiming(teacherSchedule[0].Thursday);
        setFridayTiming(teacherSchedule[0].Friday);
        setSatdayTiming(teacherSchedule[0].Saturday);
      }

      if (
        teacherSchedule[0].Sunday.length > 0 ||
        teacherSchedule[0].Monday.length > 0 ||
        teacherSchedule[0].Tuesday.length > 0 ||
        teacherSchedule[0].Wednesday.length > 0 ||
        teacherSchedule[0].Thursday.length > 0 ||
        teacherSchedule[0].Friday.length > 0 ||
        teacherSchedule[0].Saturday.length > 0
      ) {
        setUpdateWorkingHour(true);
      }
    }
  }, [teacherSchedule]);

  const handleChange = (event, week) => {
    if (week === "Sunday") {
      if (event.target.value.includes("N/A") && !sundayTiming.includes("N/A")) {
        setSundayTiming(["N/A"]);
      } else {
        setSundayTiming(event.target.value);
      }
    } else if (week === "Monday") {
      if (event.target.value.includes("N/A") && !mondayTiming.includes("N/A")) {
        setMondayTiming(["N/A"]);
      } else {
        setMondayTiming(event.target.value);
      }
    } else if (week === "Tuesday") {
      if (
        event.target.value.includes("N/A") &&
        !tuesdayTiming.includes("N/A")
      ) {
        setTuesdayTiming(["N/A"]);
      } else {
        setTuesdayTiming(event.target.value);
      }
    } else if (week === "Wednesday") {
      if (event.target.value.includes("N/A") && !weddayTiming.includes("N/A")) {
        setWeddayTiming(["N/A"]);
      } else {
        setWeddayTiming(event.target.value);
      }
    } else if (week === "Thursday") {
      if (
        event.target.value.includes("N/A") &&
        !thursdayTiming.includes("N/A")
      ) {
        setThursdayTiming(["N/A"]);
      } else {
        setThursdayTiming(event.target.value);
      }
    } else if (week === "Friday") {
      if (event.target.value.includes("N/A") && !fridayTiming.includes("N/A")) {
        setFridayTiming(["N/A"]);
      } else {
        setFridayTiming(event.target.value);
      }
    } else if (week === "Saturday") {
      if (event.target.value.includes("N/A") && !satdayTiming.includes("N/A")) {
        setSatdayTiming(["N/A"]);
      } else {
        setSatdayTiming(event.target.value);
      }
    }
  };

  const handleDelete = (e, value, week) => {
    e.preventDefault();
    if (week === "Sunday") {
      setSundayTiming((current) => _without(current, value));
    } else if (week === "Monday") {
      setMondayTiming((current) => _without(current, value));
    } else if (week === "Tuesday") {
      setTuesdayTiming((current) => _without(current, value));
    } else if (week === "Wednesday") {
      setWeddayTiming((current) => _without(current, value));
    } else if (week === "Thursday") {
      setThursdayTiming((current) => _without(current, value));
    } else if (week === "Friday") {
      setFridayTiming((current) => _without(current, value));
    } else if (week === "Saturday") {
      setSatdayTiming((current) => _without(current, value));
    }
  };

  useEffect(() => {
    // Skip the first render
    // if (isInitialRender.current) {
    console.log("mylogrender", replicate, isInitialRender.current)

    const submitForm = () => {
      setLoder(true);
      var data;

      const onSuccess = (msg) => {
        openSnackbar(msg);
        setLoder(false);

        dispatch(
          GetTeacherTiming({
            teacherId: teacher._id,
            forKids: false,
            subject: subject,
            type:"Regular"
          })
        );
        dispatch(
          GetTeacherTimingKids({
            teacherId: teacher._id,
            forKids: true,
            subject: subject,
          })
        );
        dispatch(CurrentTeacher({
          userid: teacher._id,
          timezone: time_zone.timezone
        }))
      };

      const onError = (msg) => {
        setLoder(false);
        openSnackbar(msg);
      };

      if (teacherSchedule) {
        if (updateWorkingHour) {
          data = {
            _id: teacher._id,
            Sunday: sundayTiming,
            Monday: mondayTiming,
            Tuesday: tuesdayTiming,
            Wednesday: weddayTiming,
            Thursday: thursdayTiming,
            Friday: fridayTiming,
            Saturday: satdayTiming,
            timeZone: time_zone.timezone,
            subject: subject,
            allowWeekSlotsReplication: replicate,
          };
          dispatch(TeacherWeeklyTimingKids(data, onSuccess, onError));
        } else {
          data = {
            _id: teacher._id,
            Sunday: sundayTiming,
            Monday: mondayTiming,
            Tuesday: tuesdayTiming,
            Wednesday: weddayTiming,
            Thursday: thursdayTiming,
            Friday: fridayTiming,
            Saturday: satdayTiming,
            isDemo: false,
            timeZone: time_zone.timezone,
            subject: subject,
            allowWeekSlotsReplication: replicate,
          };
          dispatch(TeacherWeeklyTimingKids(data, onSuccess, onError));
        }
      } else {
        openSnackbar('something went wrong please relode & try again');
        setLoder(false);
      }
    };

    // Call the SubmitForm function only when replicate changes
    if(isInitialRender.current===false) submitForm();
  }, [replicate]);

  useEffect(()=>{
    console.log("mylogrender mounting");
    isInitialRender.current = false;
    return () => {console.log("mylogrender unmounting");isInitialRender.current=true}
  }, [subject])

  const SubmitForm = () => {
    setLoder(true);
    var data;

    const onSuccess = (msg) => {
      openSnackbar(msg);
      setLoder(false);

      dispatch(
        GetTeacherTiming({
          teacherId: teacher._id,
          forKids: false,
          subject: subject,
          type:"Regular"
        })
      );
      dispatch(
        GetTeacherTimingKids({
          teacherId: teacher._id,
          forKids: true,
          subject: subject,
        })
      );

      //window.location.reload();
    };
    const onError = (msg) => {
      setLoder(false);
      openSnackbar(msg);
    };

    if (teacherSchedule) {
      if (updateWorkingHour) {
        data = {
          _id: teacher._id,
          Sunday: sundayTiming,
          Monday: mondayTiming,
          Tuesday: tuesdayTiming,
          Wednesday: weddayTiming,
          Thursday: thursdayTiming,
          Friday: fridayTiming,
          Saturday: satdayTiming,
          timeZone: time_zone.timezone,
          subject: subject,
          allowWeekSlotsReplication: replicate
        };
        dispatch(TeacherWeeklyTimingKids(data, onSuccess, onError));
      } else {
        data = {
          _id: teacher._id,
          Sunday: sundayTiming,
          Monday: mondayTiming,
          Tuesday: tuesdayTiming,
          Wednesday: weddayTiming,
          Thursday: thursdayTiming,
          Friday: fridayTiming,
          Saturday: satdayTiming,
          isDemo: false,
          timeZone: time_zone.timezone,
          subject: subject,
          allowWeekSlotsReplication: replicate
        };
        dispatch(TeacherWeeklyTimingKids(data, onSuccess, onError));
      }
    } else {
      openSnackbar("something went wrong please relode & try again");
    }
  };

  return (
    <>

      {teacherNormalSchedule ? (
        <>
          <SelectTimings
            classes={classes}
            handleChange={handleChange}
            handleDelete={handleDelete}
            regularTiming={regularTiming}
            teacherNormalScheduleTime={teacherNormalSchedule[0].Sunday}
            weekDay={"Sunday"}
            weekDayTiming={sundayTiming}
          />


          <SelectTimings
            classes={classes}
            handleChange={handleChange}
            handleDelete={handleDelete}
            regularTiming={regularTiming}
            teacherNormalScheduleTime={teacherNormalSchedule[0].Monday}
            weekDay={"Monday"}
            weekDayTiming={mondayTiming}
          />
          <SelectTimings
            classes={classes}
            handleChange={handleChange}
            handleDelete={handleDelete}
            regularTiming={regularTiming}
            teacherNormalScheduleTime={teacherNormalSchedule[0].Tuesday}
            weekDay={"Tuesday"}
            weekDayTiming={tuesdayTiming}
          />

          <SelectTimings
            classes={classes}
            handleChange={handleChange}
            handleDelete={handleDelete}
            regularTiming={regularTiming}
            teacherNormalScheduleTime={teacherNormalSchedule[0].Wednesday}
            weekDay={"Wednesday"}
            weekDayTiming={weddayTiming}
          />
          <SelectTimings
            classes={classes}
            handleChange={handleChange}
            handleDelete={handleDelete}
            regularTiming={regularTiming}
            teacherNormalScheduleTime={teacherNormalSchedule[0].Thursday}
            weekDay={"Thursday"}
            weekDayTiming={thursdayTiming}
          />

          <SelectTimings
            classes={classes}
            handleChange={handleChange}
            handleDelete={handleDelete}
            regularTiming={regularTiming}
            teacherNormalScheduleTime={teacherNormalSchedule[0].Friday}
            weekDay={"Friday"}
            weekDayTiming={fridayTiming}
          />

          <SelectTimings
            classes={classes}
            handleChange={handleChange}
            handleDelete={handleDelete}
            regularTiming={regularTiming}
            teacherNormalScheduleTime={teacherNormalSchedule[0].Saturday}
            weekDay={"Saturday"}
            weekDayTiming={satdayTiming}
          />

          {regularTiming ? (
            <FormControlLabel
              control={<Checkbox onChange={() => checkSameAs()} name="gilad" />}
              label="Select same as regular time"
            />
          ) : (
            ""
          )}

          <Button
            variant="contained"
            style={{ marginTop: "2rem" }}
            color="primary"
            onClick={SubmitForm}
            disabled={loder ? true : false}
          >
            {updateWorkingHour ? (
              loder ? (
                <CircularProgress />
              ) : (
                "Update"
              )
            ) : loder ? (
              <CircularProgress />
            ) : (
              "Submit"
            )}
          </Button>
        </>
      ) : (
        <Box mx="auto">
          <CircularProgress />
        </Box>
      )}

    </>
  );
};

export default KidsWorkingHours;
