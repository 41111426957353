import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//redux
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store";
//pages
import SnackbarProvider from "react-simple-snackbar";
import HomePage from "./pages/HomePage";
import SignIn from "./pages/Login";
import Profile from "./pages/Profile";
import SessionHistory from "./pages/SessionHistory";
import SessionRequests from "./pages/SessionRequests";
import UpcommingSession from "./pages/UpcommingSession";
import WorkingHour from "./pages/WorkingHour";
import AuthRouter from "./routes/AuthRoute";
import { CurrentTeacher, relode } from "./redux/actions/AuthAction";
import {
  GetTeacherTiming,
  TecherPandingSession,
  TecherPastSession,
  TecherUpcomingSession,
} from "./redux/actions/SessionAction";
import PastSession from "./pages/PastSession";
import NumberForm from "./component/forms/NumberForm";
import SendOtp from "./component/forms/OtpForm";
import PasswordForm from "./component/forms/PasswordForm";
import Bugsnag from "@bugsnag/js";
import BlockedLearner from "./pages/BlockedLearner";
import HomeworkPreview from "./pages/HomeworkPreview";
import PdfViewerPage from "./pages/PdfViewerPage";
import SessionAttendance from "./pages/SessionAttendance";
import ReferralProfile from "./pages/ReferralProfile";
import EarningsV2 from "./pages/EarningsV2";
import FeedbackPage from "./pages/FeedbackPage";
import Payouts from "./pages/Payouts";
import BookSession from "./pages/BookSession";
import ChatPage from "./pages/ChatPage";
import Schedule from "./pages/Schedule";
import DeleteAccount from "./pages/DeleteAccount";
import InitFreshChat from "./helper/freshchat";
import Faqs from "./pages/FAQs";
import SignUp from "./pages/SignUp";
import HelpfulVideo from "./pages/HelpfulVideo";
import HelpfulPDF from "./pages/HelpfulPdf";
import { ToastBar, Toaster } from "react-hot-toast";
import InstantBooking from "./pages/InstantBooking";
import Earnings from "./pages/Earnings";
import ActiveCoupons from "./pages/ActiveCoupons";
import { initAmplitude } from "./helper/amplitudeHelper";
import PaymentLink from "./pages/PaymentLink";
import DemoRequests from "./pages/DemoRequests";
import MenteesSessions from "./pages/MenteesSessions";

Bugsnag.notify(new Error("Test error"));
const Routes = () => {
  const dispatch = useDispatch();
  const teacher = JSON.parse(localStorage.getItem("TEACHER"));
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

  React.useEffect(() => {
    dispatch(relode(teacher));
    if (teacher) {
      dispatch(
        CurrentTeacher({
          userid: teacher._id,
          timezone: time_zone.timezone,
        })
      );

      dispatch(TecherPandingSession({ teacherID: teacher._id }));
      dispatch(
        TecherUpcomingSession(
          {
            teacherID: teacher._id,
            page: 1,
            limit: 10,
            timezone: time_zone.timezone,
          },
          "reload"
        )
      );
      dispatch(
        TecherPastSession({
          teacherID: teacher._id,
          page: 1,
          limit: 10,
          timezone: time_zone.timezone,
        })
      );
      if (!teacher?.isSalesPerson || !teacher?.isFluencyCounsellor)
        dispatch(
          GetTeacherTiming({
            teacherId: teacher._id,
            forKids: false,
            subject: teacher.subjects[0]?.subject,
            type:"Regular"
          })
        );
    }
    InitFreshChat(teacher);
    initAmplitude();
  }, [teacher]);

  return (
    <BrowserRouter>
      <Switch>
        <AuthRouter path="/" exact component={HomePage} />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/signup/:applicationId" exact component={SignUp} />
        <Route path="/verifynumber" exact component={NumberForm} />
        <Route path="/verifyOtp" exact component={SendOtp} />
        <Route path="/setpassword" exact component={PasswordForm} />
        <AuthRouter
          path="/upcomingsession"
          exact
          component={UpcommingSession}
        />
        <AuthRouter path="/sessionrequests" exact component={SessionRequests} />
        <AuthRouter path="/demorequests" exact component={DemoRequests} />
        <AuthRouter path="/chatpage" component={ChatPage} />
        <AuthRouter path="/schedule" component={Schedule} />
        <AuthRouter path="/book-session" exact component={BookSession} />
        <AuthRouter path="/homework" exact component={HomeworkPreview} />
        <AuthRouter path="/feedback" exact component={FeedbackPage} />
        <AuthRouter path="/blocklearner" exact component={BlockedLearner} />
        <AuthRouter path="/sessionhistory" exact component={SessionHistory} />
        <AuthRouter path="/workinghour" exact component={WorkingHour} />
        <AuthRouter path="/instantbooking" exact component={InstantBooking} />
        <AuthRouter path="/profile" exact component={Profile} />
        <AuthRouter path="/pastsession" exact component={PastSession} />
        <AuthRouter path="/attendance" exact component={SessionAttendance} />
        <AuthRouter path="/earnings" exact component={EarningsV2} />
        <AuthRouter path="/earningsV1" exact component={Earnings} />
        <AuthRouter path="/session-document" exact component={PdfViewerPage} />
        <AuthRouter path="/payouts" exact component={Payouts} />
        <AuthRouter path="/helpful-videos" exact component={HelpfulVideo} />
        <AuthRouter path="/helpful-pdfs" exact component={HelpfulPDF} />
        <AuthRouter path="/activeCoupons" component={ActiveCoupons} />
        <Route path="/delete-account" exact component={DeleteAccount} />
        <Route path="/speaker/:id" exact component={ReferralProfile} />
        <AuthRouter path="/mentee/:id" exact component={MenteesSessions} />
        <Route path="/delete-account" exact component={DeleteAccount} />
        <Route path="/faqs" exact component={Faqs} />
        <AuthRouter path="/payment-link" exact component={PaymentLink} />
      </Switch>
      <Toaster containerClassName="toastContainer">
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible ? t.enterAnimation : t.exitAnimation,
            }}
          />
        )}
      </Toaster>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <SnackbarProvider>
      <Provider store={store}>
        <Routes />
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
