import { Box, Drawer, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const CustomTableCell = styled(TableCell)({
  borderRight: "1px solid #D9E0DF",
  fontSize: "13px",
});

const NetEarningBreakDown = ({
  openDrawer,
  setOpenDrawer,
  totalPenalty,
  tds,
  bonus,
  totalHours,
  totalSessionEarning,
  referralEarning,
  noOfLateJoin,
  lateJoinPenalty,
  noOfMissedSession,
  missedSessionPenalty,
  cancelledSessions,
  startDate,
  endDate,
  fixedPay,
  isSalesPerson,
  arpu,
  avgOfferDiscount,
  demoConversationPercentage,
  revenueGenerated,
  isFluencyCounsellor,
  allowAutoSwap,
  commissionCriteria,
  cancelDemoSession,
  totalSessions,
  mentorshipConversionAmount,
  mentorConversionPercent,
  bookedDemosCount,
  demoAttended,
  newLogicForFC,
  weekWiseCriteria
}) => {

  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const timezone = time_zone?.timezone;

  let totalEarning = 0;
  if (isSalesPerson)
    totalEarning = Math.round(fixedPay + referralEarning - totalPenalty - tds);
  else if(isFluencyCounsellor)
    totalEarning = Math.round(
      // totalSessionEarning + mentorshipConversionAmount + referralEarning + bonus - totalPenalty - tds
            totalSessionEarning + mentorshipConversionAmount + referralEarning  - totalPenalty - tds

    );
  else
    totalEarning = Math.round(
      // totalSessionEarning + referralEarning + bonus - totalPenalty - tds
      totalSessionEarning + referralEarning - totalPenalty - tds

    );
  let netEarning = 0;
  if (isSalesPerson) netEarning = fixedPay + referralEarning;
  else if(isFluencyCounsellor)
    // netEarning = Math.round(Number(totalSessionEarning) + Number(mentorshipConversionAmount) + Number(referralEarning) + Number(bonus))
      netEarning = Math.round(Number(totalSessionEarning) + Number(mentorshipConversionAmount) + Number(referralEarning))

  else

    netEarning =  Number(totalSessionEarning) + Number(referralEarning);


  if(new Date(endDate) >= new Date("2024-12-01")){
    netEarning = netEarning + Number(bonus)
    totalEarning = totalEarning + Number(bonus)
  }


  // const totalPenalty =
  //   Math.round(noOfLateJoin * lateJoinPenalty) +
  //   Math.round(noOfMissedSession * missedSessionPenalty) +
  //   Number(cancelledSessions);
  const totalEarningWithOutTds = netEarning - totalPenalty;

  return (
    <Drawer
      sx={{ zIndex: 9 }}
      open={openDrawer}
      anchor={"right"}
      onClose={() => setOpenDrawer(false)}
    >
      <Box sx={{ width: 500 }}>
        <Box
          sx={{
            display: "flex",
            p: 2,
            borderBottom: "1px solid #E6EAE9",
            background: "#F2F5F4",
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ cursor: "pointer", mr: 1 }}
            onClick={() => {
              setOpenDrawer(false);
            }}
          />
          <Typography
            style={{
              color: "#082B28",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Net Earnings Breakdown
          </Typography>
        </Box>
        <Box sx={{ m: 2, p: 4, background: "#F9FBF7" }}>
          <Box
            sx={{
              color: "#0E433A",
              fontFamily: "Inter",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "28px",
              mb: 1,
            }}
          >
            Breakdown
          </Box>
          <Box
            sx={{
              color: "#647B76",
              fontFamily: "Inter",
              fontSize: "11px",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: "16px",
              mb: 2,
            }}
          >
            From {moment(startDate).format("DD-MMM")} to{" "}
            {moment(endDate).format("DD-MMM")}
          </Box>
          <Box
            sx={{
              color: "#0E433A",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: "16px",
              mb: 1,
            }}
          >
            1. Earnings From
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mx: 1.5 }}>
            {isSalesPerson ? (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Box
                  sx={{
                    color: "#647B76",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  Fixed Pay
                </Box>
                <Box
                  sx={{
                    color: "#0E433A",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "16px",
                  }}
                >
                  ₹ {fixedPay?.toLocaleString()}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Box
                  sx={{
                    color: "#647B76",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  Sessions <br/>
                  <p style={{fontSize:"0.6rem"}}>Cancelled Demo (Within 1 hr) : {cancelDemoSession}<br/>
                  Session Attended : {totalSessions}</p>
                </Box>
                <Box
                  sx={{
                    color: "#0E433A",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "16px",
                  }}
                >
                  ₹ {totalSessionEarning.toLocaleString()}
                </Box>
              </Box>
            )}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Box
                sx={{
                  color: "#647B76",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                Referral
              </Box>
              <Box
                sx={{
                  color: "#0E433A",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "16px",
                }}
              >
                ₹ {referralEarning.toLocaleString()}
              </Box>
            </Box>

            { (isFluencyCounsellor) &&
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Box
                sx={{
                  color: "#647B76",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                Mentorship commission ({mentorConversionPercent}%)
              </Box>
              <Box
                sx={{
                  color: "#0E433A",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "16px",
                }}
              >
                ₹ {Math.round(mentorshipConversionAmount || 0).toLocaleString()}
              </Box>
            </Box>
            }
            {
            !isSalesPerson && new Date(endDate) >= new Date("2024-12-01")
            ? (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                  {/* <Box
                    sx={{
                      color: "#4A6EE0",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textDecorationLine: "underline",
                      mr: 1,
                    }}
                  >
                    Bonus Amount
                  </Box> */}
                  {/* <Box
                    sx={{
                      color: "#647B76",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    ({totalHours})
                  </Box> */}
                </Box>
                {/* <Box
                  sx={{
                    color: "#0E433A",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "16px",
                  }}
                >
                  ₹ {bonus.toLocaleString()}
                </Box> */}
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              width: "100%",
              color: "#647B76",
              borderRadius: "1px",
              mb: 2,
            }}
          >
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - -
          </Box>
          <Box
            sx={{
              color: "#0E433A",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: "16px",
              mb: 1,
            }}
          >
            2. Penalties
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mx: 1.5 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Box
                sx={{
                  color: "#647B76",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                Late Join Sessions
                <span style={{ fontStyle: "italic" }}>
                  {" "}
                  ({noOfLateJoin} × ₹{lateJoinPenalty}/session)
                </span>
              </Box>
              <Box
                sx={{
                  color: "#EF4444",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "16px",
                }}
              >
                - ₹{Math.round(noOfLateJoin * lateJoinPenalty).toLocaleString()}
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Box
                sx={{
                  color: "#647B76",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                Missed Sessions
                <span style={{ fontStyle: "italic" }}>
                  ({noOfMissedSession} × ₹{missedSessionPenalty}/session)
                </span>
              </Box>
              <Box
                sx={{
                  color: "#EF4444",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "16px",
                }}
              >
                - ₹{" "}
                {Math.round(
                  noOfMissedSession * missedSessionPenalty
                ).toLocaleString()}
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  color: "#647B76",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                Canceled Sessions
              </Box>
              <Box
                sx={{
                  color: "#EF4444",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "16px",
                }}
              >
                - ₹ {cancelledSessions}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              color: "#647B76",
              borderRadius: "1px",
              mb: 2,
            }}
          >
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - -
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
              mx: 1.5,
            }}
          >
            <Box
              sx={{
                color: "#647B76",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              Total <span style={{ fontStyle: "italic" }}>(1 + 2)</span>
            </Box>
            <Box
              sx={{
                color: "#0E433A",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "16px",
              }}
            >
              ₹ {totalEarningWithOutTds.toLocaleString()}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
              mx: 1.5,
            }}
          >
            <Box
              sx={{
                color: "#647B76",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              TDS <span style={{ fontStyle: "italic" }}>(10% of total)</span>
            </Box>
            <Box
              sx={{
                color: "#0E433A",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "16px",
              }}
            >
              - ₹ {tds.toLocaleString()}
            </Box>
          </Box>
          <Box
            sx={{
              height: "44px",
              px: 1.5,
              py: 0.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "16px",
              background: "#EEF4E8",
              mt: 2,
            }}
          >
            <Box
              sx={{
                color: "#647B76",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Grand Total
            </Box>
            <Box
              sx={{
                color: "#0E433A",
                textAlign: "right",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "20px",
              }}
            >
              ₹ {totalEarning.toLocaleString()}
            </Box>
          </Box>
        </Box>

        {isSalesPerson || isFluencyCounsellor ? (
          <Box sx={{ m: 2, px: 4, py: 2, background: "#F9FBF7" }}>
            <div style={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
              <div>
                <span style={{ fontWeight: 600 }}>Total Revenue:</span>{" "}
                {Math.round(revenueGenerated)}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Conversion:</span>{" "}
                {Math.round(demoConversationPercentage)}%
              </div>
            </div>
            <Box
              sx={{
                color: "#082B28",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                mb: 2,
              }}
            >
              Criteria to become eligible for{" "}
              {isSalesPerson ? "referral" : "25% conversion slab"}
            </Box>

            <TableContainer>
              <Table
                aria-label="a dense table"
                style={{ border: "1px solid #D9E0DF" }}
              >
                {
                  (isFluencyCounsellor && newLogicForFC)?(
                    <TableHead>
                    <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                      <CustomTableCell align="center">
                        Week
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        Criteria
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        Current Value
                      </CustomTableCell>
                      <TableCell align="center">Criteria Passed</TableCell>
                    </TableRow>
                  </TableHead>
                  ):(
                    <TableHead>
                    <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                      <CustomTableCell align="center">
                        Criteria
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        Current Value
                      </CustomTableCell>
                      <TableCell align="center">Criteria Passed</TableCell>
                    </TableRow>
                    </TableHead>
                  )
                }
                {
                  (!(isFluencyCounsellor && newLogicForFC))?(
                    <TableBody>
                  {commissionCriteria.minArpu ? (
                    <TableRow
                      style={{
                        background: "white",
                      }}
                    >
                      <CustomTableCell align="center">
                        Min. Avg. Arpu of ₹{commissionCriteria.minArpu}
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        ₹ {Math.round(arpu)}
                      </CustomTableCell>
                      <TableCell align="center">
                        {Math.round(arpu) >= commissionCriteria.minArpu ? (
                          <CheckIcon sx={{ color: "#3EA175" }} />
                        ) : (
                          <CloseIcon sx={{ color: "#EF4444" }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {commissionCriteria.maxDiscountPerc ? (
                    <TableRow
                      style={{
                        background: "white",
                      }}
                    >
                      <CustomTableCell align="center">
                        Max. Avg. discount value of{" "}
                        {commissionCriteria.maxDiscountPerc}%
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        {Math.round(avgOfferDiscount)} %
                      </CustomTableCell>
                      <TableCell align="center">
                        {Math.round(avgOfferDiscount) <=
                        commissionCriteria.maxDiscountPerc ? (
                          <CheckIcon sx={{ color: "#3EA175" }} />
                        ) : (
                          <CloseIcon sx={{ color: "#EF4444" }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  <TableRow
                    style={{
                      background: "white",
                    }}
                  >
                    <CustomTableCell align="center">
                      Min. {commissionCriteria.minDemosPerMonth} demos per month
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {isFluencyCounsellor? bookedDemosCount :demoAttended}
                    </CustomTableCell>
                    <TableCell align="center">
                      {(isFluencyCounsellor? bookedDemosCount :demoAttended) >= commissionCriteria.minDemosPerMonth ? (
                        <CheckIcon sx={{ color: "#3EA175" }} />
                      ) : (
                        <CloseIcon sx={{ color: "#EF4444" }} />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
                  ):(
                    <TableBody>
                          {
                            weekWiseCriteria.map((data,i) => {
                              return (
                                <TableRow
                                  style={ {
                                    background: "white",
                                  } } key={i}
                                >
                                  <CustomTableCell align="center">
                                    {`${moment(data.startOfWeek).tz(timezone).format("DD/MM/YYYY")} - ${moment(data.endOfWeek).tz(timezone).format("DD/MM/YYYY")}`}
                                  </CustomTableCell>
                                  <CustomTableCell align="center">
                                    Min {commissionCriteria?.minDemosPerMonth ?? 90} demo per week
                                  </CustomTableCell>
                                  <CustomTableCell align="center">
                                    {data.demoSessionCount}
                                  </CustomTableCell>
                                  <TableCell align="center">
                                    { (data.weekCriteriaSatisfied) ? (
                                      <CheckIcon sx={ { color: "#3EA175" } } />
                                    ) : (
                                      <CloseIcon sx={ { color: "#EF4444" } } />
                                    ) }
                                  </TableCell>
                                </TableRow>
                              )
                            })
                          }
                    </TableBody>
                  )
                }
              </Table>
            </TableContainer>
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
};

export default NetEarningBreakDown;
